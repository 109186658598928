// noinspection ES6UnusedImports
import Vue from "vue";
import axios from "axios";
//import store from '../store'

// axios.defaults.baseURL = process.env.VUE_APP_API
// axios.defaults.baseURL = 'http://192.168.3.251:5000/api/';
axios.defaults.headers.common.Accept = "application/json";
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error(error);
    // if (error.response.status === 401) {
    // 	  return new Promise((resolve) => {
    // 	    store.dispatch('auth/refresh')
    // 	      .then((token) => {
    // 	        let config = error.config
    // 	        config.headers.Authorization = `Bearer ${token.accessToken}`
    // 	        return resolve(axios(config))
    // 	      })
    // 	      .catch(() => {
    // 	        store.dispatch('auth/logout').then(() => {
    // 	          location.reload()
    // 	        })
    // 	      })
    // 	  })
    // }
    // return Promise.reject(error);
  }
);

Vue.$http = axios;
Object.defineProperty(Vue.prototype, "$http", {
  get() {
    return axios;
  },
});
