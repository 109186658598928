import axios from "axios";
import fetch from "node-fetch";

export const MaxApi = {
  apiUrl: "https://uk1.maximizercrmlive.com/api195/Data.svc/json/",
  backEndUrl: "https://signis.maxcloud.ca:81/api",
  authUrl: "",
  companyUrl: "",
  documentUrl: "",
  schemaReadUrl: "",
  noteReadUrl: "",
  token: "",

  async init() {
    await this.checkToken();
    this.authUrl = `${this.apiUrl}/Authenticate`;
    this.companyUrl = `${this.apiUrl}/AbEntryRead`;
    this.schemaReadUrl = `${this.apiUrl}/SchemaRead`;
    this.noteReadUrl = `${this.apiUrl}/NoteRead`;
    this.docReadUrl = `${this.apiUrl}/DocumentRead`;
    this.docDownloadUrl = `${this.apiUrl}/DocumentDownload`;
  },

  checkToken() {
    if (typeof localStorage.maxToken === "undefined") {
      this.generateToken();
    } else {
      this.token = localStorage.maxToken;
    }
  },

  // Authenticate Maximizer API
  async generateToken() {
    const authRequest = await axios.get(
      `${this.backEndUrl}/maximizer/authenticate`
    );
    const authRes = authRequest.data;

    if (authRes.Code === 0) {
      localStorage.maxToken = authRes.Data.Token;
      this.token = authRes.Data.Token;
    } else {
      console.error("Could not authenticate Maximizer API");
    }
  },

  async queryAbList(queryObject) {
    if (queryObject) {
      const abRequest = {
        Token: this.token,
        AbEntry: {
          Scope: {
            Fields: {
              key: {
                ID: 1,
                Number: 1,
                EntityType: 1,
              },
              Key: 1,
              Type: 1,
              CompanyName: 1,
              MrMs: 1,
              FirstName: 1,
              MiddleName: 1,
              LastName: 1,
              FullName: 1,
              Position: 1,
              Salutation: 1,
              Department: 1,
              Division: 1,
              Phone: 1,
              Phone1: 1,
              Phone2: 1,
              Phone3: 1,
              Phone4: 1,
              Email: 1,
              Email1: 1,
              Email2: 1,
              Email3: 1,
              WebSite: 1,
              ParentKey: 1,
              "Udf/$TYPEID(57996)": 1,
              Address: {
                key: 1,
                Description: 1,
                AddressLine1: 1,
                AddressLine2: 1,
                City: 1,
                Country: 1,
                StateProvince: 1,
                ZipCode: 1,
                Default: 1,
                ParentKey: 1,
              },
            },
          },
          Criteria: {
            SearchQuery: queryObject,
          },
        },
      };

      let abRes = await this.postApi(this.companyUrl, abRequest);
      abRes = abRes.data;

      if (abRes.Code === 0) {
        return abRes.AbEntry.Data;
      } else {
        console.error("Could not get Address Book list from Maximizer API");
        return false;
      }
    }
  },

  async queryAbCinemaJuryList(queryObject) {
    if (queryObject) {
      let abQuery = this.getBaseAbQuery();
      abQuery.AbEntry.Criteria = {};
      abQuery.AbEntry.Criteria.SearchQuery = queryObject;

      // Step 1. get Ab Entries
      let abRes = await this.postApi(this.companyUrl, abQuery);
      let abResKeys = [];
      abRes = abRes.data;
      if (abRes.Code === 0) {
        abRes = abRes.AbEntry.Data;
        abRes.sort((a, b) => a.FirstName.localeCompare(b.FirstName));
        abResKeys = abRes.map((el) => {
          return el.Key;
        });
      } else {
        console.error("Could not get Address Book list from Maximizer API");
        return false;
      }

      if (abRes.length === 0) {
        return [];
      }

      // Step 2. get related notes
      let noteQuery = this.getBaseNoteQuery();
      let noteSearchQuery = { $OR: [] };
      abResKeys.forEach((el) => {
        const search = { $EQ: { Parent: el } };
        noteSearchQuery.$OR.push(search);
      });
      noteQuery.Note.Criteria.SearchQuery = noteSearchQuery;
      let noteRes = await this.postApi(this.noteReadUrl, noteQuery);
      noteRes = noteRes.data;
      if (noteRes.Code === 0) {
        noteRes = noteRes.Note.Data;
      } else {
        console.error("Could not get Notes list from Maximizer API");
        return false;
      }

      // Step 3. Assign notes to Ab Entries
      abRes.forEach((el) => {
        el.Biography = noteRes.find((x) => x.Parent.ID == el.key.ID) || null;
        if (el.Biography) {
          el.Biography = el.Biography.RichText;
        }
      });

      // Step 4. Find profile images
      let docQuery = this.getBaseDocumentQuery();
      let docSearchQuery = {
        $AND: [noteSearchQuery, { $EQ: { Category: "Profile Picture" } }],
      };
      docQuery.Document.Criteria.SearchQuery = docSearchQuery;
      let docRes = await this.postApi(this.docReadUrl, docQuery);
      docRes = docRes.data;
      if (docRes.Code === 0) {
        docRes = docRes.Document.Data;
      } else {
        console.error("Could not get Address Book list from Maximizer API");
        return false;
      }

      // Step 5. Download and assign profile images
      for (let index = 0; index < docRes.length; index++) {
        const doc = await fetch(`${this.docDownloadUrl}/${docRes[index].Key}`, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        });
        const blob = await doc.blob();
        const img = URL.createObjectURL(blob);
        const relatedAb = await abRes.find(
          (x) => x.key.ID == docRes[index].Parent.ID
        );
        relatedAb.ProfileImage = img;
      }

      return abRes;
    }
  },

  async getAbList() {
    const abRequest = {
      Token: this.token,
      AbEntry: {
        Scope: {
          Fields: {
            key: {
              ID: 1,
              Number: 1,
              EntityType: 1,
            },
            Key: 1,
            Type: 1,
            CompanyName: 1,
            MrMs: 1,
            FirstName: 1,
            MiddleName: 1,
            LastName: 1,
            FullName: 1,
            Position: 1,
            Salutation: 1,
            Department: 1,
            Division: 1,
            Phone: 1,
            Phone1: 1,
            Phone2: 1,
            Phone3: 1,
            Phone4: 1,
            Email: 1,
            Email1: 1,
            Email2: 1,
            Email3: 1,
            WebSite: 1,
            ParentKey: 1,
            "Udf/$TYPEID(57996)": 1,
            Address: {
              key: 1,
              Description: 1,
              AddressLine1: 1,
              AddressLine2: 1,
              City: 1,
              Country: 1,
              StateProvince: 1,
              ZipCode: 1,
              Default: 1,
              ParentKey: 1,
            },
          },
        },
      },
    };

    let abRes = await this.postApi(this.companyUrl, abRequest);
    abRes = abRes.data;

    if (abRes.Code === 0) {
      return abRes.AbEntry.Data;
    } else {
      console.error("Could not get Address Book list from Maximizer API");
      return false;
    }
  },

  async getUdfOptions(name) {
    const req = {
      Token: this.token,
      Schema: {
        Scope: {
          Fields: {
            Key: 1,
            Attributes: 1,
            Name: 1,
            Items: {
              Key: 1,
              Value: 1,
            },
          },
        },
        Criteria: {
          SearchQuery: {
            $AND: [{ $EQ: { AppliesTo: "AbEntry" } }, { $EQ: { Name: name } }],
          },
        },
      },
    };

    let res = await this.postApi(this.schemaReadUrl, req);
    res = res.data;

    if (res.Code === 0) {
      return res.Schema.Data[0].Items;
    } else {
      console.error("Could not get UDF Options from Maximizer API");
    }
  },

  async querySchema(queryObject) {
    var schemaReadURL = `${this.apiUrl}/SchemaRead`;

    if (queryObject) {
      const schemaRequest = {
        Token: this.token,
        Schema: {
          Scope: {
            Fields: {
              Key: 1,
              Alias: 1,
              Name: 1,
              Type: 1,
              Items: 1,
              AppliesTo: 1,
              Folder: 1,
              Creator: 1,
              CreationDate: 1,
              Inactive: 1,
              ReadOnly: 1,
              Mandatory: 1,
              Formula: 1,
              Attributes: 1,
            },
          },
          Criteria: {
            SearchQuery: queryObject,
          },
        },
      };

      let schemaRes = await this.postApi(schemaReadURL, schemaRequest);
      schemaRes = schemaRes.data;

      if (schemaRes.Code === 0) {
        return schemaRes;
      } else {
        console.error("Could not get Address Book list from Maximizer API");
      }
    }
  },

  getBaseAbQuery() {
    return {
      Token: this.token,
      AbEntry: {
        Scope: {
          Fields: {
            key: {
              ID: 1,
              Number: 1,
              EntityType: 1,
            },
            Key: 1,
            Type: 1,
            CompanyName: 1,
            MrMs: 1,
            FirstName: 1,
            MiddleName: 1,
            LastName: 1,
            FullName: 1,
            Position: 1,
            Salutation: 1,
            Department: 1,
            Division: 1,
            Phone: 1,
            Phone1: 1,
            Phone2: 1,
            Phone3: 1,
            Phone4: 1,
            Email: 1,
            Email1: 1,
            Email2: 1,
            Email3: 1,
            WebSite: 1,
            ParentKey: 1,
            "Udf/$TYPEID(57996)": 1,
            Address: {
              key: 1,
              Description: 1,
              AddressLine1: 1,
              AddressLine2: 1,
              City: 1,
              Country: 1,
              StateProvince: 1,
              ZipCode: 1,
              Default: 1,
              ParentKey: 1,
            },
          },
        },
      },
    };
  },

  getBaseNoteQuery() {
    return {
      Token: this.token,
      Note: {
        Scope: {
          Fields: {
            key: {
              ID: 1,
              EntityType: 1,
            },
            Parent: {
              ID: 1,
            },
            RichText: 1,
          },
        },
        Criteria: {},
      },
    };
  },

  getBaseDocumentQuery() {
    return {
      Token: this.token,
      Document: {
        Scope: {
          Fields: {
            Key: 1,
            key: {
              ID: 1,
              EntityType: 1,
            },
            Parent: {
              ID: 1,
            },
            Name: 1,
          },
        },
        Criteria: {},
      },
    };
  },

  async postApi(url, data) {
    try {
      const res = await axios({
        method: "post",
        url: url,
        data: JSON.stringify(data),
        headers: {
          "Content-Type": "text/plain",
        },
      });

      // Take care of expired token
      if (
        typeof res.data.Msg !== "undefined" &&
        res.data.Msg[0].ErrorCode === -20003
      ) {
        await this.generateToken();

        if (data.Token != this.token) {
          // Rebind token
          data.Token = this.token;

          // Call function again with newly regenerated token
          return this.postApi(url, data);
        } else {
          return;
        }
      }

      return res;
    } catch (error) {
      console.error(`Maximizer API post failed.`);
      console.error(error);
    }
  },
};
