<template>
  <div :class="{ GoogleMap: !isSearch, GoogleMapSmall: isSearch }"></div>
</template>

<script>
import gmapsInit from "../utils/gmaps.js";
import MarkerClusterer from "@google/markerclusterer";

export default {
  name: "GoogleMap",
  props: ["location", "isSearch"],
  data: () => ({
    centerLocation: {},
    zoom: 0,
    map: {},
    markers: [],
    google: null,
    propertyIds: [],
    properties: [],
    unitIds: [],
  }),
  watch: {
    location: function (val) {
      if (val.length > 0) {
        this.initialize();
      } else {
        this.initialize();
      }
    },
    openMapDetails: function (val) {
      if (val == "true") {
        if (this.selectedLocation != false && this.selectedLocation != null) {
          this.$emit("open", this.selectedLocation);
        }
      }
    },
    selectedLocation: function (val) {
      if (val) {
        if (this.openMapDetails != false && this.openMapDetails != null) {
          this.$emit("open", this.selectedLocation);
        }
      }
    },
  },
  computed: {
    selectedLocation() {
      if (this.$route.query.key) {
        return this.$route.query.key;
      } else return false;
    },
    openMapDetails() {
      if (this.$route.query.open == "true") {
        return this.$route.query.open;
      } else return false;
    },
  },
  methods: {
    async initialize() {
      try {
        //init google maps
        const google = await gmapsInit();
        this.google = google;

        //init map options
        var mapOptions = {
          center: new google.maps.LatLng(0, 0),
          zoom: Math.ceil(Math.log2(960)) - 8,
          //gestureHandling: 'greedy'
        };

        //init map with maptions and element
        this.map = new google.maps.Map(this.$el, mapOptions);

        const locations = this.location;

        // places Markers and InfoWindow
        this.placeMarkers(this.map, locations, google);

        //  checks if open map details is open
        if (this.openMapDetails == "true") {
          if (this.selectedLocation != false && this.selectedLocation != null) {
            this.$emit("open", this.selectedLocation);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },

    // places markers and infowindow
    async placeMarkers(map, locations, google) {
      //Init marker array
      let markersArr = [];

      locations.forEach((location) => {
        if (
          location.Coordinates != false &&
          typeof location.Coordinates != "undefined"
        ) {
          const newLat =
            Number(location.Coordinates.lat) + Math.random() / 25000;
          const newLng =
            Number(location.Coordinates.lng) + Math.random() / 25000;
          const latLng = new google.maps.LatLng(newLat, newLng);

          // Init infoWindow formating
          let address = "";
          var addressLine = "";
          var city = "";
          var stateProvince = "";
          var zipCode = "";
          var cityLine = "";
          var countryLine = "";
          let company = "";
          let individual = "";
          let view = "";

          company = `
            <v-row class="subtitle-3 ">
              COMPANY
            </v-row>
            <br />
            <v-row class="subtitle-2">
              ${location.CompanyName}
            </v-row>
          `;

          if (location.FirstName) {
            individual = ` <hr class="hr-thin my-2">
                    <v-row class="subtitle-3">INDIVIDUAL</v-row><br />
                              <v-row class="subtitle-2 font-weight-bold">${location.FirstName} ${location.LastName}<v-row>`;
          }
          if (location.Address.AddressLine1 || location.Address.AddressLine2) {
            addressLine = `<v-row class="subtitle-2">${location.Address.AddressLine1} ${location.Address.AddressLine2} </v-row>
                              <br v-if="(location.Address.AddressLine1) || (location.Address.AddressLine2)"/>`;
          }
          if (location.Address.City) {
            city = `${location.Address.City}, `;
          }
          if (location.Address.StateProvince) {
            stateProvince = `${location.Address.StateProvince} `;
          }
          if (location.Address.ZipCode) {
            zipCode = `${location.Address.ZipCode} `;
          }
          if (city || stateProvince || zipCode) {
            cityLine = `<v-row class="subtitle-2">${city} ${stateProvince} ${zipCode} </v-row>
                              <br />`;
          }
          if (location.Address.Country) {
            countryLine = `<v-row class=" subtitle-2"> ${location.Address.Country} </v-row>`;
          }

          address = `<hr class="hr-thin my-2">
                              ${addressLine}
                              ${cityLine}${countryLine}`;

          view = `<br />
                              <br />
                              <p class="text-center">
                                  <a class="v-btn outlined headline" @click.native="myFunction" href='/#/?key=${location.key.ID}&open=true'>
                                      VIEW
                                  </a>
                              </p>`;

          // Setting infoWindow
          const infoHtml = `<div class="infoWindow" id="window-${location.key.ID}">
                              ${company}
                              ${individual}
                              ${address}
                              ${view}
                          </div>`;

          const infowindow = new google.maps.InfoWindow({
            content: infoHtml,
          });

          // Put Marker
          const marker = new google.maps.Marker({
            map: map,
            position: latLng,
          });

          // Add listener to marker to open infowindow
          marker.addListener("click", function () {
            infowindow.open(map, marker);
            map.setCenter(marker.getPosition());
          });

          // Close marker on map click
          map.addListener("click", function () {
            infowindow.close(map, marker);
          });

          // Selects marker and centers if there is only 1 result
          if (locations.length == 1) {
            infowindow.open(map, marker);
            map.setCenter(marker.getPosition());
          }

          markersArr.push(marker);
        }
      });

      // Init marker clusterer
      new MarkerClusterer(map, markersArr, {
        imagePath:
          "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
        gridSize: 40,
        minimumClusterSize: 2,
        maxZoom: 18,
      });

      //Put markers on the map
      /*await locations
        //map through all locations and places a marker & infowindow
        .forEach(location => {
          if (
            location.Address.Country !== null ||
            location.Address.Country !== ""
          ) {
            var marker;
            const geocoder = new google.maps.Geocoder();

            // Geocodes using location information
            geocoder.geocode(
              {
                address:
                  location.Address.AddressLine1 +
                  " " +
                  location.Address.City +
                  " " +
                  location.Address.StateProvince +
                  " " +
                  location.Address.Country
              },
              async function(results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                  // Init infoWindow formating
                  let address = "";
                  var addressLine = "";
                  var city = "";
                  var stateProvince = "";
                  var zipCode = "";
                  var cityLine = "";
                  var countryLine = "";
                  let company = "";
                  let individual = "";
                  let view = "";

                  company = ` <v-row class="subtitle-3 ">COMPANY</v-row>
                              <br />
                              <v-row class="subtitle-2">${location.CompanyName}</v-row>`;

                  if (location.FirstName) {
                    individual = ` <hr class="hr-thin my-2">
                    <v-row class="subtitle-3">INDIVIDUAL</v-row><br />
                              <v-row class="subtitle-2 font-weight-bold">${location.FirstName} ${location.LastName}<v-row>`;
                  }
                  if (
                    location.Address.AddressLine1 ||
                    location.Address.AddressLine2
                  ) {
                    addressLine = `<v-row class="subtitle-2">${location.Address.AddressLine1} ${location.Address.AddressLine2} </v-row>
                              <br v-if="(location.Address.AddressLine1) || (location.Address.AddressLine2)"/>`;
                  }
                  if (location.Address.City) {
                    city = `${location.Address.City}, `;
                  }
                  if (location.Address.StateProvince) {
                    stateProvince = `${location.Address.StateProvince} `;
                  }
                  if (location.Address.ZipCode) {
                    zipCode = `${location.Address.ZipCode} `;
                  }
                  if (city || stateProvince || zipCode) {
                    cityLine = `<v-row class="subtitle-2">${city} ${stateProvince} ${zipCode} </v-row>
                              <br />`;
                  }
                  if (location.Address.Country) {
                    countryLine = `<v-row class=" subtitle-2"> ${location.Address.Country} </v-row>`;
                  }

                  address = `<hr class="hr-thin my-2">
                              ${addressLine}
                              ${cityLine}${countryLine}`;

                  view = `<br />
                              <br />
                              <p class="text-center">
                                  <a class="v-btn outlined headline" @click.native="myFunction" href='/#/?key=${location.key.ID}&open=true'>
                                      VIEW
                                  </a>
                              </p>`;

                  // Setting infoWindow
                  const infoHtml = `<div class="infoWindow" id="window-${location.key.ID}">
                              ${company}
                              ${individual}
                              ${address}
                              ${view}
                          </div>`;

                  const infowindow = new google.maps.InfoWindow({
                    content: infoHtml
                  });

                  // Offsetting location to allow for markers in clusters to be individually selected
                  var newLat =
                    results[0].geometry.location.lat() + Math.random() / 25000;
                  var newLng =
                    results[0].geometry.location.lng() + Math.random() / 25000;

                  var latLng = new google.maps.LatLng(newLat, newLng);

                  // Put Marker
                  marker = await new google.maps.Marker({
                    map: map,
                    position: latLng
                  });

                  // Add listener to marker to open infowindow
                  await marker.addListener("click", function() {
                    infowindow.open(map, marker);
                    map.setCenter(marker.getPosition());
                  });

                  // Close marker on map click
                  await map.addListener("click", function() {
                    infowindow.close(map, marker);
                  });
                  await markerArray.push(marker);
                  await markerCluster.addMarker(marker);

                  // Selects marker and centers if there is only 1 result
                  if (locations.length == 1) {
                    infowindow.open(map, marker);
                    map.setCenter(marker.getPosition());
                  }
                }
              }
            );
          }
        });*/
    },
  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
}
.GoogleMap {
  width: 960px;
  height: 640px;
}
.GoogleMapSmall {
  width: 960px;
  height: 400px;
}
.infoWindow {
  font-size: 12px;
}
.hr-thin {
  height: 1px;
}
.info-window-heading {
}
</style>
