<template>
  <v-container fluid class="pa-0">
    <v-container class="content-container">
      <v-row>
        <Spinner ref="spinner"></Spinner>
        <v-col cols="3">
          <v-autocomplete
            label="Refine Search By"
            :items="ddlFilters"
            item-text="name"
            item-value="value"
            v-model="filterSelected"
            class="d-flex align-self-center"
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col cols="2">
          <v-autocomplete
            label="Filter"
            :items="ddlTypeFilters"
            item-text="name"
            item-value="value"
            v-model="filterTypeSelected"
            class="d-flex align-self-center"
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col cols="3" class="d-flex align-end justify-center">
          <v-autocomplete
            v-if="filterSelected == 'desks'"
            label="Desks"
            :items="desks"
            item-text="Value"
            item-value="Key"
            v-model="deskSelected"
            class="d-flex align-self-center"
            hide-details
          ></v-autocomplete>
          <v-text-field
            v-else
            label="Search..."
            prepend-inner-icon="search"
            outlined
            single-line
            v-model.lazy="search"
            align-self="end"
            @keyup.enter="searchSignis()"
            clearable
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="1" class="d-flex align-center"
          ><v-btn color="primary" icon @click="searchSignis()">
            <v-icon>mdi-magnify</v-icon></v-btn
          ></v-col
        >
        <v-col cols="2" class="d-flex align-center">
          <div>
            {{ numResults != null ? numResults + " Results" : "" }}
          </div>
        </v-col>
        <v-col cols="1" class="d-flex align-center justify-end">
          <a
            @click="isMapSelected = true"
            :class="{ 'font-weight-bold': isMapSelected }"
            >Map
          </a>
          <v-divider class="mx-3" vertical inset></v-divider>
          <a
            @click="isMapSelected = false"
            :class="{ 'font-weight-bold': !isMapSelected }"
            >List
          </a>
        </v-col>
      </v-row>
      <v-row
        ><v-progress-linear
          v-show="loading"
          indeterminate
          color="primary"
        ></v-progress-linear
      ></v-row>

      <!-- MAP DETAILS (OPENS ON VIEW CLICK) -->
      <v-row
        v-if="isMapSelected && isMapDetailOpen"
        style="border: 1px solid"
        class="my-5"
      >
        <v-col cols="11">
          <v-row>
            <v-col class="ml-4">
              <v-row class="font-weight-bold">
                <text-highlight :queries="organizationHighlight">
                  {{ mapDetails.CompanyName }}
                </text-highlight>
              </v-row>
              <v-row>
                <span>
                  {{ mapDetails.Address.AddressLine1 }}
                  {{ mapDetails.Address.AddressLine2 }}
                </span>
              </v-row>
              <v-row>
                <span>
                  <text-highlight :queries="cityHighlight">
                    {{ mapDetails.Address.City
                    }}{{ mapDetails.Address.StateProvince ? ", " : " " }}
                  </text-highlight>
                  {{ mapDetails.Address.ZipCode }}
                  <text-highlight :queries="countryHighlight">
                    {{ mapDetails.Address.Country }}
                  </text-highlight>
                </span>
              </v-row>
              <v-row>
                <span>
                  {{
                    mapDetails.Phone ? formatPhoneNumber(mapDetails.Phone) : ""
                  }}
                </span>
              </v-row>
              <v-row>
                <span>
                  <a
                    v-if="mapDetails.Email"
                    :href="'mailto:' + mapDetails.Email"
                    color="primary"
                    dark
                    style="text-decoration: none"
                  >
                    {{ mapDetails.Email }}
                  </a>
                </span>
              </v-row>
              <v-row>
                <text-highlight :queries="deskHighlight">
                  {{
                    mapDetails[`Udf/$TYPEID(57996)`]
                      ? mapDetails[`Udf/$TYPEID(57996)`]
                      : "" | filterDesk
                  }}
                </text-highlight>
              </v-row>
              <v-row>
                <span>
                  {{ mapDetails.WebSite ? "Website:" : "" }}
                  <a class="ml-1" :href="mapDetails.WebSite" target="_blank">
                    {{ mapDetails.WebSite }}
                  </a>
                </span>
              </v-row>
            </v-col>
            <v-col sm="1">
              <v-row></v-row>
            </v-col>
            <v-col v-if="mapDetails.Contacts" sm="5">
              <v-row v-for="contact in mapDetails.Contacts" :key="contact.Key">
                <v-col class="pt-0 pb-0">
                  <v-row class="justify-end">
                    <text-highlight
                      class="font-weight-bold"
                      :queries="individualNameHightlight"
                    >
                      {{ contact.MrMs }} {{ contact.FirstName }}
                      {{ contact.LastName }}
                    </text-highlight>
                    <span v-if="contact.Position">
                      , {{ contact.Position }}
                    </span>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-else sm="5">
              <v-row>
                <v-col class="pt-0 pb-0">
                  <v-row class="justify-end">
                    <text-highlight
                      class="font-weight-bold"
                      :queries="individualNameHightlight"
                    >
                      {{ mapDetails.MrMs }} {{ mapDetails.FirstName }}
                      {{ mapDetails.LastName }}
                    </text-highlight>
                    <span v-if="mapDetails.Position">
                      , {{ mapDetails.Position }}
                    </span>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col col="1" class="text-end">
          <v-btn color="primary" icon @click="closeMapDetails">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <!-- END OF MAP DETAILS (OPENS ON VIEW CLICK) -->

      <!-- START OF GOOGLE MAP -->
      <v-row v-show="isMapSelected" class="mb-5">
        <googleMap
          :location="addressBookList"
          :isSearch="isSearch"
          @open="openMapDetails"
        ></googleMap>
      </v-row>
      <!-- END OF GOOGLE MAP -->

      <!-- START OF LIST SEARCH -->
      <v-template
        v-for="result in addressBookList"
        :key="result.Key"
        v-show="isSearch == true"
        :name="'ListDetails' + result.key"
      >
        <template v-if="filterTypeSelected == 'cinemaJury'">
          <v-row>
            <v-col class="py-0">
              <div class="sg-item-divider"></div>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="2">
              <v-row class="justify-start pl-3">
                <img
                  v-if="result.ProfileImage"
                  :src="result.ProfileImage"
                  style="width: 145px"
                />
                <img
                  v-else
                  src="@/assets/portrait-placeholder.png"
                  style="width: 145px"
                />
              </v-row>
            </v-col>
            <v-col>
              <v-row class="justify-start pl-2">
                <text-highlight
                  class="font-weight-bold text-uppercase"
                  :queries="individualNameHightlight"
                >
                  {{ result.MrMs }} {{ result.FirstName }} {{ result.LastName }}
                </text-highlight>
              </v-row>
              <v-row v-if="result.Position" class="justify-start pl-2">
                {{ result.Position }}
              </v-row>
              <v-row v-if="result.Email" class="justify-start pl-2">
                {{ result.Email }}
              </v-row>
              <v-row v-if="result.Phone" class="justify-start pl-2">
                {{ result.Phone }}
              </v-row>
            </v-col>
            <v-col>
              <v-row class="justify-end font-weight-bold pr-3">
                <text-highlight :queries="organizationHighlight">
                  {{ result.CompanyName }}
                </text-highlight>
              </v-row>
              <v-row class="justify-end pr-3">
                {{ result.Address.AddressLine1 }}
                {{ result.Address.AddressLine2 }}
              </v-row>
              <v-row class="justify-end pr-3">
                <text-highlight :queries="cityHighlight">
                  {{ result.Address.City
                  }}{{ result.Address.StateProvince ? "," : " " }}
                </text-highlight>
                <span class="pl-1" v-if="result.Address.StateProvince">
                  {{ result.Address.StateProvince }}
                </span>
                <span class="pl-1" v-if="result.Address.ZipCode">
                  {{ result.Address.ZipCode }}
                </span>
                <text-highlight class="pl-1" :queries="countryHighlight">
                  {{ result.Address.Country }}
                </text-highlight>
              </v-row>
              <v-row v-if="result.Phone" class="justify-end pr-3">
                {{ result.Phone }}
              </v-row>
              <v-row class="justify-end pr-3">
                <a
                  v-if="result.Email"
                  :href="'mailto:' + result.Email"
                  color="primary"
                  dark
                  style="text-decoration: none"
                >
                  {{ result.Email }}
                </a>
              </v-row>
              <v-row class="justify-end pr-3">
                <text-highlight :queries="deskHighlight">
                  {{
                    result[`Udf/$TYPEID(57996)`]
                      ? result[`Udf/$TYPEID(57996)`]
                      : "" | filterDesk
                  }}
                </text-highlight>
              </v-row>
              <v-row v-if="result.WebSite" class="justify-end pr-3">
                Website:
                <a class="ml-1" :href="result.WebSite" target="_blank">
                  {{ result.WebSite }}
                </a>
              </v-row>
            </v-col>
          </v-row>
          <v-row
            v-if="result.Biography"
            v-html="result.Biography"
            class="px-3 mt-1 mb-3"
          ></v-row>
        </template>
        <template v-else>
          <v-row>
            <v-col sm="12" class="pr-0 py-0">
              <v-divider class="my-0 mx-0"></v-divider>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="6">
              <v-row class="font-weight-bold">
                <text-highlight :queries="organizationHighlight" class="pl-3">
                  {{ result.CompanyName }}
                </text-highlight>
              </v-row>
              <v-row>
                <span class="pl-3">
                  {{ result.Address.AddressLine1 }}
                  {{ result.Address.AddressLine2 }}
                </span>
              </v-row>
              <v-row>
                <span class="pl-3">
                  <text-highlight :queries="cityHighlight">
                    {{ result.Address.City
                    }}{{ result.Address.StateProvince ? ", " : " " }}
                  </text-highlight>
                  {{ result.Address.ZipCode }}
                  <text-highlight :queries="countryHighlight">
                    {{ result.Address.Country }}
                  </text-highlight>
                </span>
              </v-row>
              <v-row v-if="result.Phone">
                <span class="pl-3">
                  {{ result.Phone }}
                </span>
              </v-row>
              <v-row>
                <span class="pl-3">
                  <a
                    v-if="result.Email"
                    :href="'mailto:' + result.Email"
                    color="primary"
                    dark
                    style="text-decoration: none"
                  >
                    {{ result.Email }}
                  </a>
                </span>
              </v-row>
              <v-row>
                <text-highlight :queries="deskHighlight" class="pl-3">
                  {{
                    result[`Udf/$TYPEID(57996)`]
                      ? result[`Udf/$TYPEID(57996)`]
                      : "" | filterDesk
                  }}
                </text-highlight>
              </v-row>
              <v-row v-if="result.WebSite">
                <span class="pl-3">
                  Website:
                  <a class="ml-1" :href="result.WebSite" target="_blank">
                    {{ result.WebSite }}
                  </a>
                </span>
              </v-row>
            </v-col>
            <v-col sm="1">
              <v-row></v-row>
            </v-col>
            <v-col v-if="result.Contacts" sm="5">
              <v-row v-for="contact in result.Contacts" :key="contact.Key">
                <v-col class="pt-0 pb-0">
                  <v-row class="justify-end">
                    <text-highlight
                      class="font-weight-bold"
                      :queries="individualNameHightlight"
                    >
                      {{ contact.MrMs }} {{ contact.FirstName }}
                      {{ contact.LastName }}
                    </text-highlight>
                    <span v-if="contact.Position">
                      , {{ contact.Position }}
                    </span>
                  </v-row>
                  <v-row class="justify-end">
                    {{
                      contact.Phone
                        ? contact.Email + ", " + contact.Phone
                        : contact.Email
                    }}
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-else sm="5">
              <v-row>
                <v-col class="pt-0 pb-0">
                  <v-row class="justify-end">
                    <text-highlight
                      class="font-weight-bold"
                      :queries="individualNameHightlight"
                    >
                      {{ result.MrMs }} {{ result.FirstName }}
                      {{ result.LastName }}
                    </text-highlight>
                    <span v-if="result.Position">
                      , {{ result.Position }}
                    </span>
                  </v-row>
                  <v-row class="justify-end">
                    {{
                      result.Phone
                        ? result.Email + ", " + result.Phone
                        : result.Email
                    }}
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </template>
      </v-template>
      <!-- END OF LIST SEARCH -->

      <!-- START OF NO RECORDS FOUND MESSAGE -->
      <template
        v-if="
          addressBookList.length == 0 && isSearch == true && loading == false
        "
        name="NumRecords"
        ><div class="mt-3">No records were found that match your search</div>
      </template>
      <!-- END OF NO RECORDS FOUND MESSAGE -->
      <template v-if="isSearch == false && loading == false && !isMapSelected"
        ><div class="mt-3">No search provided</div>
      </template>
    </v-container>
  </v-container>
</template>

<script>
import { MaxApi } from "@/helpers/MaxApi.js";
import * as formatHelper from "@/helpers/FormatHelpers.js";
import googleMap from "../components/GoogleMap.vue";
import Spinner from "../components/Spinner.vue";
import TextHighlight from "vue-text-highlight";
import axios from "axios";

export default {
  name: "Home",
  components: {
    googleMap,
    "text-highlight": TextHighlight,
    Spinner,
  },
  data: () => ({
    search: "",
    ddlFilters: [
      { name: "Organization Name", value: "organizationName" },
      { name: "Individual Name", value: "individualName" },
      { name: "Desks", value: "desks" },
      { name: "City", value: "city" },
      { name: "Country", value: "country" },
    ],
    ddlTypeFilters: [
      { name: "All", value: "all" },
      { name: "Cinema Jury", value: "cinemaJury" },
      // { name: 'Journalists', value: 'journalists' }
    ],
    addressBookList: [],
    filterSelected: "organizationName",
    filterTypeSelected: "all",
    deskSelected: "",
    isSearch: false,
    loading: false,
    tabs: 2,
    isMapSelected: true,
    numResults: null,
    locations: null,
    isMapDetailOpen: false,
    mapDetails: {},
    desks: [],
    maxUdfs: {
      serviceToSignis: {
        name: "Udf/$TYPEID(9)",
        options: {
          cinemaJury: "1",
        },
      },
    },
    organizationHighlight: "",
    individualNameHightlight: "",
    deskHighlight: "",
    cityHighlight: "",
    countryHighlight: "",
    backEndUrl: "https://signis.maxcloud.ca:81/api",
  }),
  filters: {
    // filters Desk to name using value
    filterDesk(val) {
      var desks = [
        { name: "Radio Desk", value: "1" },
        { name: "TV Desk", value: "2" },
        { name: "Media Education Desk", value: "3" },
        { name: "Journalism Desk", value: "4" },
        { name: "Cinema Desk", value: "5" },
        { name: "Digital Desk", value: "6" },
      ];
      if (val) {
        var filteredArray = desks.filter(function (item) {
          return val.indexOf(item.value) > -1;
        });
        var result = "";
        if (filteredArray.length > 0) {
          for (var obj of filteredArray) {
            result += obj.name + ", ";
          }
          result = result.slice(0, -2);
          return result;
        }
      }
    },
  },
  computed: {},
  watch: {
    filterTypeSelected: function () {
      this.searchSignis();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      // Make sure $refs are available
      await this.$nextTick(() => {
        this.$refs.spinner.on();
      });

      // authenticates maximizer api and gets token
      await MaxApi.init();
      this.closeMapDetails();

      this.desks = await MaxApi.getUdfOptions("Desks");
      this.abCoordinates = await this.getCoordinates();

      //searches all addressbook entries
      let abList = await MaxApi.getAbList();
      abList = await this.prepareAbList(abList);
      this.addressBookList = abList;
      this.numResults = this.addressBookList.length;

      this.$refs.spinner.off();
    },

    //opens map details using if key is entered in query string
    // opens when map view is clicked
    openMapDetails(key) {
      if (key) {
        let index = this.addressBookList.findIndex((x) => x.key.ID == key);
        if (index > -1) {
          this.mapDetails = this.addressBookList[index];
          this.isMapDetailOpen = true;
        }
      }
    },

    // closes map details
    closeMapDetails() {
      this.isMapDetailOpen = false;
      this.$router.push({ path: "/", query: { open: "false" } });
    },

    // query addressbook list using query object => search criteria in api search
    async queryAbList(queryObject) {
      // api call to maximizer using search query
      let abList = [];
      if (this.filterTypeSelected === "cinemaJury") {
        abList = await MaxApi.queryAbCinemaJuryList(queryObject);
      } else {
        abList = await MaxApi.queryAbList(queryObject);
      }

      if (abList === false) {
        alert("Could not get data from Maximizer");
      } else {
        await this.refreshAbList(abList);
      }
    },

    async refreshAbList(abList) {
      abList = await this.prepareAbList(abList);
      this.addressBookList = abList;
      this.numResults = this.addressBookList.length;
      this.loading = false;
      this.isSearch = true;
    },

    async prepareAbList(abList) {
      let abListContacts = abList.filter(
        (abEntry) => abEntry.Type == "Contact"
      );
      let abListCompanies = abList.filter(
        (abEntry) => abEntry.Type == "Individual" || abEntry.Type == "Company"
      );

      await abListCompanies.forEach(async (el) => {
        // Get geocoding data
        const coords = this.abCoordinates.find((x) => x.ab_id == el.key.ID);
        if (typeof coords === "undefined" && el.Address.Country != "") {
          el.Coordinates = await this.generateCoordinates(el);
          // Refresh coordinates list
          this.getCoordinates();
        } else {
          el.Coordinates = coords;
        }

        // Get corresponding contacts
        const contacts = abListContacts.filter(
          (abEntry) => abEntry.ParentKey == el.Key
        );
        if (contacts.length > 0) {
          el.Contacts = contacts;
        } else {
          el.Contacts = null;
        }
      });

      return abListCompanies;
    },

    // handles search and filters by passing parameters to correct methods
    async searchSignis() {
      if (
        this.search ||
        this.filterSelected == "desks" ||
        this.filterTypeSelected
      ) {
        //trim search whitespaces at beginning and end
        if (this.search) {
          this.search = this.search.trim();
        } else {
          this.search = "";
        }

        this.addressBookList = [];
        // sets isSearch and loading && resets all highlights
        this.isSearch = true;
        this.loading = true;
        this.resetHighlight();
        this.closeMapDetails();

        let typeFilterQuery = {};
        if (this.filterTypeSelected != "all") {
          switch (this.filterTypeSelected) {
            case "cinemaJury":
              typeFilterQuery = {
                $EQ: {
                  [this.maxUdfs.serviceToSignis.name]: this.maxUdfs
                    .serviceToSignis.options.cinemaJury,
                },
              };
              break;
            default:
              break;
          }
        }

        // switch for search filters
        switch (this.filterSelected) {
          case "organizationName":
            this.searchOrganizationName(typeFilterQuery);
            break;
          case "individualName":
            this.searchIndividualName(typeFilterQuery);
            break;
          case "desks":
            this.searchDesk(typeFilterQuery);
            break;
          case "city":
            this.searchCity(typeFilterQuery);
            break;
          case "country":
            this.searchCountry(typeFilterQuery);
            break;
          default:
            break;
        }
      } else {
        alert("No Search Entered");
      }
    },

    // search by organization name
    async searchOrganizationName(typeFilterQuery) {
      this.organizationHighlight = this.search;
      const organizationNameQuery = {
        $AND: [
          {
            $LIKE: { CompanyName: "%" + this.search + "%" },
          },
          typeFilterQuery,
        ],
      };
      this.queryAbList(organizationNameQuery);
    },

    // search by individual name
    // handles full name, last name, first name, 2 first names, 2 last names
    async searchIndividualName(typeFilterQuery) {
      this.individualNameHightlight = this.search;

      let nameInitQuery = [
        { $LIKE: { FirstName: "%" + this.search + "%" } },
        { $LIKE: { LastName: "%" + this.search + "%" } },
      ];

      let searchArray = [];
      searchArray = this.search.split(" ");
      //   handles search if two strings
      if (searchArray.length == 2) {
        nameInitQuery.push({
          $AND: [
            { $LIKE: { FirstName: "%" + searchArray[0] + "%" } },
            { $LIKE: { LastName: "%" + searchArray[1] + "%" } },
          ],
        });
        nameInitQuery.push({
          $LIKE: {
            FirstName: "%" + searchArray[0] + " " + searchArray[1] + "%",
          },
        });
        nameInitQuery.push({
          $LIKE: {
            LastName: "%" + searchArray[0] + " " + searchArray[1] + "%",
          },
        });
      }
      //  handles search if more than 2 strings
      else if (searchArray.length > 2) {
        nameInitQuery.push({
          $AND: [
            {
              $LIKE: {
                FirstName: "%" + searchArray[0] + " " + searchArray[1] + "%",
              },
            },
            { $LIKE: { LastName: "%" + searchArray[2] + "%" } },
          ],
        });
        nameInitQuery.push({
          $AND: [
            {
              $LIKE: {
                FirstName: "%" + searchArray[0] + "%",
              },
            },
            {
              $LIKE: {
                LastName: "%" + searchArray[1] + " " + searchArray[2] + "%",
              },
            },
          ],
        });
      }

      // final name query string
      const nameQuery = {
        $AND: [
          {
            $OR: nameInitQuery,
          },
          typeFilterQuery,
        ],
      };

      let abList = [];
      // Do not fetch contacts if filter is set to Cinema Jury
      if (this.filterTypeSelected === "cinemaJury") {
        abList = await MaxApi.queryAbCinemaJuryList(nameQuery);
      } else {
        abList = await MaxApi.queryAbList(nameQuery);

        const contacts = abList.filter((x) => x.Type === "Contact");
        if (contacts.length > 0) {
          // Get unique keys of contacts parent companies
          const parentCompaniesKeys = contacts
            .map((x) => x.ParentKey)
            .filter((value, index, self) => self.indexOf(value) === index);
          const parentCompaniesQueryArr = parentCompaniesKeys.map((x) => {
            return {
              $EQ: {
                Key: x,
              },
            };
          });
          const parentCompaniesQuery = { $OR: parentCompaniesQueryArr };
          const parentCompanies = await MaxApi.queryAbList(
            parentCompaniesQuery
          );
          // Append parent companies to the ab entries list
          abList = abList.concat(parentCompanies);
        }
      }

      await this.refreshAbList(abList);
    },

    // search by city
    async searchCity(typeFilterQuery) {
      this.cityHighlight = this.search;
      const cityQuery = {
        $AND: [
          {
            $LIKE: {
              Address: {
                City: "%" + this.search + "%",
              },
            },
          },
          typeFilterQuery,
        ],
      };
      this.queryAbList(cityQuery);
    },

    // search by country
    async searchCountry(typeFilterQuery) {
      this.countryHighlight = this.search;
      const countryQuery = {
        $AND: [
          {
            $LIKE: {
              Address: {
                Country: "%" + this.search + "%",
              },
            },
          },
          typeFilterQuery,
        ],
      };
      this.queryAbList(countryQuery);
    },

    // searches desk
    // filters desk options and outputs the value for API search
    async searchDesk(typeFilterQuery) {
      const deskKey = this.desks.find((x) => x.Key == this.deskSelected).Key;
      this.deskHighlight = this.desks.find(
        (x) => x.Key == this.deskSelected
      ).Value;
      const query = {
        $AND: [
          {
            $EQ: {
              "Udf/$TYPEID(57996)": deskKey,
            },
          },
          typeFilterQuery,
        ],
      };

      this.queryAbList(query);
    },

    // formats phone number
    formatPhoneNumber(phoneNumber) {
      return formatHelper.formatPhoneNumber(phoneNumber);
    },

    // resets all the text highlights
    resetHighlight() {
      this.organizationHighlight = "";
      this.individualNameHightlight = "";
      this.deskHighlight = "";
      this.cityHighlight = "";
      this.countryHighlight = "";
    },

    async getCoordinates() {
      let list = await axios.get(`${this.backEndUrl}/max_ab_entry/get_list`);
      list = list.data;
      return list;
    },

    async generateCoordinates(el) {
      const address =
        el.Address.AddressLine1 +
        " " +
        el.Address.City +
        " " +
        el.Address.StateProvince +
        " " +
        el.Address.Country;
      const data = {
        abEntryId: el.key.ID,
        abAddress: address,
      };

      let coords = await axios.post(
        `${this.backEndUrl}/max_ab_entry/generate_coordinates`,
        data
      );
      return coords.data;
    },
  },
};
</script>
<style scoped>
.content-container {
  max-width: 960px;
  margin: 0 auto !important;
  float: none !important;
  min-height: 100vh;
}
.sg-item-divider {
  height: 2px;
  background-color: var(--v-primary-base);
}
</style>
