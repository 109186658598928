<template>
  <v-app class="white">
    <v-container class="content-container px-0 pb-0" fluid>
      <v-row>
        <v-col cols="4" class="d-flex justify-left">
          <img
            alt="Signis Logo"
            contain
            src="@/assets/signis-logo.jpg"
            height="70px"
          />
        </v-col>
        <v-col cols="4" class="d-flex justify-center align-center">
          <div class="sg-heading-common">Directory Search</div>
        </v-col>
        <v-col cols="4" class="d-flex justify-end align-center">
          <v-btn
            color="primary"
            outlined
            href="http://signis.net/"
            target="_blank"
          >
            Back to SIGNIS Portal
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="px-0">
      <div class="sg-header-divider"></div>
    </v-container>
    <!-- <v-container fluid class="px-0">
			<v-toolbar color="#3c4882" dark flat center>
				<v-spacer></v-spacer>
				<v-btn text>
          <span>news</span>
        </v-btn>
        <v-divider vertical></v-divider>
        <v-btn text to="/about">
          <span>about</span>
        </v-btn>
        <v-divider vertical></v-divider>
        <v-btn text>
          <span>our action</span>
        </v-btn>
        <v-divider vertical></v-divider>
        <v-btn text>
          <span>about us</span>
        </v-btn>
        <v-divider vertical></v-divider>
        <v-btn text>
          <span>publication</span>
        </v-btn>
        <v-divider vertical></v-divider>
        <v-btn text>
          <span>partners</span>
        </v-btn>
        <v-divider vertical></v-divider>
        <v-btn text>
          <span>around the world</span>
        </v-btn>
        <v-divider vertical></v-divider>
        <v-btn text>
          <span>contact</span>
        </v-btn>
        <v-divider vertical></v-divider>
        <v-btn text>
          <span>blogs</span>
        </v-btn>
        <v-divider vertical></v-divider>
        <v-btn class="ml-1" text to="/">
          <span>search</span>
        </v-btn>
				<v-spacer></v-spacer>
			</v-toolbar>
		</v-container> -->
    <v-content>
      <router-view></router-view>
    </v-content>
    <v-container fluid class="pa-0">
      <footer>
        <div class="sg-footer-divider"></div>
        <div class="sg-footer">
          <v-container class="content-container px-0 pb-0" fluid fill-height>
            <v-row>
              <v-col cols="3" class="d-flex justify-center align-center">
                <v-icon class="white--text"> mdi-phone </v-icon>
                <div class="pl-2 white--text subtitle-2">
                  +32 (0)2 7349708
                  <br />
                  FAX: + 32 (0)2 7347018
                </div>
              </v-col>
              <v-col cols="3" class="d-flex justify-center align-center">
                <v-icon class="white--text"> mdi-email </v-icon>
                <div class="pl-2 white--text subtitle-2">news@signis.net</div>
              </v-col>
              <v-col cols="3" class="d-flex justify-center align-center pr-0">
                <v-btn
                  class="sg-btn-footer"
                  color="primary"
                  outlined
                  href="http://signis.net/"
                  target="_blank"
                  small
                >
                  Back to SIGNIS Portal
                </v-btn>
              </v-col>
              <v-col cols="3" class="d-flex justify-center align-center">
                <a
                  class="white--text subtitle-2 sg-footer-link-zita"
                  href="https://zita.ca/"
                  target="_blank"
                >
                  Powered by<img
                    src="@/assets/zita-logo.png"
                    alt="Zita Associates Inc."
                  />
                </a>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </footer>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {},

  data: () => ({
    //
  }),
};
</script>
<style scoped>
.theme--light.v-application {
  background: white;
}
.content-container {
  max-width: 960px;
  margin: 0 auto !important;
  float: none !important;
}
.sg-header-divider {
  height: 5px;
  background-color: var(--v-primary-base);
}
.sg-footer-divider {
  height: 20px;
  background-color: #38436c !important;
}
.sg-footer {
  height: 150px;
  background-color: var(--v-primary-base);
}
.sg-heading-common {
  color: #444;
  font-weight: 900;
  font-size: 2rem;
}
.sg-btn-footer {
  background-color: white;
  border: none;
}
.sg-footer-link-zita {
  text-decoration: none;
  text-align: center;
  line-height: 40px !important;
}
.sg-footer-link-zita img {
  margin: auto;
  margin-left: 0.5rem;
  vertical-align: middle;
  display: inline-block;
}
</style>
